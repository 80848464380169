<template>
  <div class="add">
    <Breadcrumb name1="艺术品管理" name2="合成关系列表"/>
    <!--    卡片视图-->
    <el-card>
      <!--      提示区-->
      <el-alert
        title="添加合成关系"
        type="info"
        center
        :closable="false"
        show-icon
      >
      </el-alert>
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-position="top"
        label-width="100px"
      >
        <el-tabs
          :tab-position="'left'"
        >
          <el-tab-pane label="基本信息" name="0">
            <!-- 级联选择器 -->
            <el-form-item label="合成目标" prop="target_id">
              <el-select v-model="addForm.target_id" placeholder="请选择">
                <el-option
                  v-for="item in infoList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="材料是否销毁" prop="is_destroy">
              <el-select v-model="addForm.is_destroy" placeholder="请选择">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所需类型" prop="classify">
              <el-select v-model="addForm.classify" placeholder="请选择" @change="setShow">
                <el-option label="版画相同作品" value="1"></el-option>
                <el-option label="版画不同作品" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所需数量" prop="number" v-if="addForm.classify=='2'">
              <el-input v-model="addForm.art_nums[0]"></el-input>
            </el-form-item>
            <el-form-item label="所需艺术品" prop="art_ids">
              <!-- <el-checkbox-group v-model="addForm.art_ids" size="medium">
                <template v-for="item in entityList">
                  <el-checkbox v-if="item.sold_mode == 0"
                  :key="item.id"
                  :label="item.id"
                  border>{{item.name}}</el-checkbox>
                </template>
              </el-checkbox-group> -->
              <!-- 选单个的 -->
              <el-select v-model="addForm.art_ids[0]" placeholder="请选择艺术品" v-if="addForm.classify=='2'">
                <el-option
                  v-for="item in entityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <!-- 按钮 -->
              <el-button type="primary" size="mini" @click="pushArtData('art')" v-if="addForm.classify=='1'">增加</el-button>
              <!-- 选项 -->
              <template  v-if="addForm.classify=='1'">
                <el-row v-for="(val, key) in addForm.art_ids" :key="'row'+key">
                  <el-col>
                    <el-select v-model="addForm.art_ids[key]" :key="'art'+key" placeholder="请选择艺术品">
                      <el-option
                        v-for="item in entityList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                    <el-input v-model="addForm.art_nums[key]" :key="'num'+key" placeholder="数量" type="number" style="width:100px;margin-top:5px;margin-left:5px"></el-input>
                    <el-button type="text" size="small" style="margin-left:15px" @click="removeArtData('art', key)">删除</el-button>
                  </el-col>
                </el-row>
        
              </template>
            </el-form-item>

            <el-form-item label="所需盲盒" prop="box_ids" v-if="addForm.classify=='1'">
              <!-- <el-checkbox-group v-model="addForm.box_ids" size="medium">
                <el-checkbox 
                  v-for="item in boxList"
                  :key="item.id"
                  :label="item.id"
                  border>{{item.name}}</el-checkbox>
              </el-checkbox-group> -->
              <!-- 按钮 -->
              <el-button type="primary" size="mini" @click="pushArtData('box')">增加</el-button>
              <!-- 选项 -->
              <template v-for="(val, key) in addForm.box_ids">

                <el-row :key="'row'+key">
                  <el-col>
                    <el-select v-model="addForm.box_ids[key]" :key="'art'+key" placeholder="请选择盲盒">
                      <el-option
                        v-for="item in boxList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                    <el-input v-model="addForm.box_nums[key]" :key="'num'+key" placeholder="数量" type="number" style="width:100px;margin-top:5px;margin-left:5px"></el-input>
                    <el-button type="text" size="small" style="margin-left:15px" @click="removeArtData('box', key)">删除</el-button>
                  </el-col>
                </el-row>
        
              </template>
            </el-form-item>
            <el-form-item label="Token范围" prop="token_ids" v-if="addForm.classify=='2'">
              <el-input v-model="addForm.token_ids" type="textarea" placeholder="200,201,202,203"></el-input>
            </el-form-item>
            <el-form-item label="合成概率" prop="factor">
              <el-input v-model="addForm.factor" placeholder="1,0,0,0"></el-input>
            </el-form-item>
            <el-button type="primary" class="AddBtn" @click="submitData" v-if="id===0">添加</el-button>
            <el-button type="primary" class="AddBtn" @click="submitEditData" v-else>修改</el-button>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-card>

  </div>
</template>

<script>
import router from '@/router'
import _ from 'lodash'
import { syntheticFormRulesMixin } from '@/common/mixin.js'
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'Add',
  components: {
    Breadcrumb,
  },
  mixins: [syntheticFormRulesMixin],
  data() {
    return {
      // 添加合成表单数据对象
      id: 0,
      addForm: {
        classify: '1', //合成需要的作品类型
        target_id: '', //艺术品合成目标
        // number: '',
        art_ids: [],
        art_nums: [],
        box_ids: [],
        box_nums: [],
        token_ids: '',
        factor: '1,0,0,0',
        is_destroy: '',
      },
      infoList:[],//目标艺术品
      entityList:[],//普通艺术品
      boxList:[],//盲盒艺术品
    }
  },
  created() {
    const _that = this
    _that.id = router.currentRoute.params.id
    _that.id = isNaN(_that.id) ? 0 : _that.id
    if (_that.id) {
      _that.getDetailById(_that.id)
    }
    _that.getInfoList(0)
    _that.getInfoList(2)
    _that.getInfoList(3)
    _that.pushArtData('art')
    _that.pushArtData('box')
  },
  methods: {
    removeArtData(type, key){
      if (type == "art"){
        this.addForm.art_ids.splice(key,1);
        this.addForm.art_nums.splice(key,1);
      }else{
        this.addForm.box_ids.splice(key,1);
        this.addForm.box_nums.splice(key,1);
      }
      
    },
    pushArtData(type){
      if (type == "art"){
        this.addForm.art_ids.push("")
        this.addForm.art_nums.push("")
      }else{
        this.addForm.box_ids.push("")
        this.addForm.box_nums.push("")
      }
    },
    //是否显示
    setShow(val){
      this.addForm.token_ids = ""
      this.addForm.art_ids = []
      this.addForm.art_nums = []
      this.addForm.box_ids = []
      this.addForm.box_nums = []
      if (val == 1){
        _that.pushArtData('art')
        _that.pushArtData('box')
      }
    },
    getInfoList(sold_mode) {
      this.$http({
        method: 'get',
        url: '/api/v1/admin/art-info/sold-mode',
        params: {sold_mode:sold_mode,page: 1, page_size: 10000},
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          // 'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        // loading: loading
      })
        .then((res) => {
          if (sold_mode == 0){
            this.entityList = res.data.list
          }else if(sold_mode == 2){
            this.boxList = res.data.list
          }else if(sold_mode == 3){
            this.infoList = res.data.list
          }else{
            console.log("getInfoList error")
          }
          
        })
        .catch((res) => {
          return this.$message.error('获取艺术品列表失败!')
        })
    },
    getDetailById(id) {
      const _that = this;
      _that.$http({
        method: 'get',
        url: '/api/v1/admin/art-synthetic/' + id,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => {
          if (res.data.status == 200){
            _that.$message.success('获取艺术品信息成功!')
            var data = res.data.result
            _that.addForm.classify = data.classify.toString()
            _that.addForm.target_id = data.target_id
            _that.addForm.is_destroy = data.is_destroy?"1":"0"
            if (data.art_ids != ""){
              var artIds = data.art_ids.split(",")
              for(var i = 0; i < artIds.length; i++){
                  artIds[i] = Number(artIds[i])
              }
              _that.addForm.art_ids = artIds
            }
            if (data.art_nums != ""){
              var artNums = data.art_nums.split(",")
              for(var i = 0; i < artNums.length; i++){
                  artNums[i] = artNums[i]
              }
              _that.addForm.art_nums = artNums
            }
            if (data.box_ids != ""){
              var boxIds = data.box_ids.split(",")
                for(var i = 0; i < boxIds.length; i++){
                  boxIds[i] = Number(boxIds[i])
              }
              _that.addForm.box_ids = boxIds
            }
            if (data.box_nums != ""){
              var boxNums = data.box_nums.split(",")
                for(var i = 0; i < boxNums.length; i++){
                  boxNums[i] = boxNums[i]
              }
              _that.addForm.box_nums = boxNums
            }
            _that.addForm.token_ids = data.token_ids
            _that.addForm.factor = data.factor
          }else{
            _that.$message.error('获取艺术品信息失败!')
          }
        })
        .catch((res) => {
          _that.$message.error('获取艺术品列表失败!')
        })
    },
    submitData() {
      const _that = this
      _that.$refs["addFormRef"].validate((valid) => {
        if (valid) {
          if (_that.addForm.art_ids==""){
            _that.addForm.art_ids = []
            _that.addForm.art_nums = []
          }
          if (_that.addForm.box_ids==""){
            _that.addForm.box_ids = []
            _that.addForm.box_nums = []
          }
          _that.addForm.classify = Number(_that.addForm.classify)
          // _that.addForm.number = Number(_that.addForm.number)
          _that.addForm.is_destroy = _that.addForm.is_destroy=="1"?true:false;
          _that.$http({
            method: 'post',
            url: '/api/v1/admin/art-synthetic',
            data: _that.addForm,
            timeout: 10000,
            headers: {
              token: window.sessionStorage.getItem('token'),
              // 'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json; charset=UTF-8',
            },
            // loading: loading
          })
          .then((res) => {
            if (res.data.status == 200){
              _that.$message.success('新增成功')
              _that.$router.push('/synthetic')
            }else{
              _that.$message.error(res.data.message)
              return
            }
          })
          .catch((res) => {
            return _that.$message.error('新增操作失败了哦,请检查数据后重试')
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitEditData() {
      const _that = this
      _that.$refs["addFormRef"].validate((valid) => {
        if (valid) {
          if (_that.addForm.art_ids==""){
            _that.addForm.art_ids = []
            _that.addForm.art_nums = []
          }
          if (_that.addForm.box_ids==""){
            _that.addForm.box_ids = []
            _that.addForm.box_nums = []
          }
          _that.addForm.classify = Number(_that.addForm.classify)
          // _that.addForm.number = Number(_that.addForm.number)
          _that.addForm.is_destroy = _that.addForm.is_destroy=="1"?true:false;
          _that.$http({
            method: 'put',
            url: '/api/v1/admin/art-synthetic/' + _that.id,
            data: _that.addForm,
            timeout: 10000,
            headers: {
              token: window.sessionStorage.getItem('token'),
              'Content-Type': 'application/json; charset=UTF-8',
            },
          })
          .then((res) => {
            if (res.data.status == 200){
              _that.$message.success('修改成功')
              _that.$router.push('/synthetic')
            }else{
              _that.$message.error(res.data.message)
              return
            }
          })
          .catch((res) => {
            return _that.$message.error('修改操作失败')
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  computed: {},
}
</script>

<style lang="less" scoped>
.el-steps {
  margin: 15px 0;
}
.el-step__title {
  font-size: 13px;
}
.el-checkbox {
  margin: 0 5px 0 0 !important;
}
.perviewImg {
  width: 100%;
}
.AddBtn {
  margin-top: 15px;
}
</style>
